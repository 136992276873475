html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

ul,
ol,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
p,
blockquote,
figure,
form,
fieldset,
input,
legend,
pre,
abbr,
button {
  margin: 0;
  padding: 0
}

pre,
code,
address,
caption,
th,
figcaption {
  font-size: 1em;
  font-weight: normal;
  font-style: normal
}

fieldset,
iframe {
  border: 0
}

caption,
th {
  text-align: left
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

main,
summary,
details {
  display: block
}

audio,
canvas,
video,
progress {
  vertical-align: baseline
}

button {
  background: none;
  border: 0;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  overflow: visible;
  vertical-align: inherit
}

button:disabled {
  cursor: default
}

.button{
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: 1.1764805882;
  font-weight: 400;
  letter-spacing: -0.022em;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
  min-width: 28px;
  padding: 8px 16px;
  border-radius: 980px;
  background: /*#0071e3*/#ff9f89;
  color: #fff;
}

.button:hover {
  text-decoration: none
}

.button:focus, .active {
  box-shadow: 0 0 0 4px /*rgba(0, 125, 250, 0.6)*/ rgba(255, 159, 137, 0.6);
  outline: none
}

.button:focus[data-focus-method="mouse"]:not(input):not(textarea):not(select),
.button:focus[data-focus-method="touch"]:not(input):not(textarea):not(select) {
  box-shadow: none
}

.button:active {
  outline: none
}

.button:disabled,
.button.disabled {
  cursor: default
}


.button:hover {
  background: #0077ED
}

.button:active {
  background: #006EDB
}

.button:disabled,
.button.disabled {
  background: #0071e3;
  color: #fff;
  opacity: .32
}

.button-block {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-radius: 8px
}

.button-neutral {
  background: #1d1d1f;
  color: #fff
}

.button-neutral:hover {
  background: #272729
}

.button-neutral:active {
  background: #18181A
}

.button-neutral:disabled,
.button-neutral.disabled {
  background: #1d1d1f;
  color: #fff;
  opacity: .32
}

.button-secondary {
  background: #e8e8ed;
  color: #000
}

.button-secondary:hover {
  background: #EBEBF0
}

.button-secondary:active {
  background: #E6E6EB
}

.button-secondary:disabled,
.button-secondary.disabled {
  background: #e8e8ed;
  color: #000;
  opacity: .56
}

.button-secondary-alpha {
  background: rgba(0, 0, 0, 0.08);
  color: #000
}

.button-secondary-alpha:hover {
  background: rgba(0, 0, 0, 0.07)
}

.button-secondary-alpha:active {
  background: rgba(0, 0, 0, 0.09)
}

.button-secondary-alpha:disabled,
.button-secondary-alpha.disabled {
  background: rgba(0, 0, 0, 0.08);
  color: #000;
  opacity: .56
}

.button-super {
  font-size: 17px;
  line-height: 1.17648;
  font-weight: 400;
  letter-spacing: -.022em;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  min-width: 28px;
  padding-left: 31px;
  padding-right: 31px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 28px
}


.button-super.button-block {
  border-radius: 12px
}

.button-elevated {
  font-size: 17px;
  line-height: 1.17648;
  font-weight: 400;
  letter-spacing: -.022em;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  min-width: 26px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 22px
}


.button-elevated.button-block {
  border-radius: 10px
}

.button-reduced {
  font-size: 12px;
  line-height: 1.33337;
  font-weight: 400;
  letter-spacing: -.01em;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  min-width: 23px;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 12px
}

.button-reduced.button-block {
  border-radius: 5px
}

:focus {
  outline: 4px solid rgba(0, 125, 250, 0.6);
  outline-offset: 1px
}

:focus[data-focus-method="mouse"]:not(input):not(textarea):not(select),
:focus[data-focus-method="touch"]:not(input):not(textarea):not(select) {
  outline: none
}

::-moz-focus-inner {
  border: 0;
  padding: 0
}

html {
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 106.25%;
  quotes: "â€œ""â€"
}



body {
  font-size: 17px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -.022em;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  background-color: #fff;
  color: #1d1d1f;
  font-style: normal
}



body,
input,
textarea,
select,
button {
  font-synthesis: none;
  -moz-font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: ltr;
  text-align: left
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #1d1d1f
}

h1 img,
h2 img,
h3 img,
h4 img,
h5 img,
h6 img {
  display: block;
  margin: 0
}

h1+*,
h2+*,
h3+*,
h4+*,
h5+*,
h6+* {
  margin-top: .8em
}

h1+h1,
h1+h2,
h1+h3,
h1+h4,
h1+h5,
h1+h6,
h2+h1,
h2+h2,
h2+h3,
h2+h4,
h2+h5,
h2+h6,
h3+h1,
h3+h2,
h3+h3,
h3+h4,
h3+h5,
h3+h6,
h4+h1,
h4+h2,
h4+h3,
h4+h4,
h4+h5,
h4+h6,
h5+h1,
h5+h2,
h5+h3,
h5+h4,
h5+h5,
h5+h6,
h6+h1,
h6+h2,
h6+h3,
h6+h4,
h6+h5,
h6+h6 {
  margin-top: .4em
}

p+h1,
ul+h1,
ol+h1,
p+h2,
ul+h2,
ol+h2,
p+h3,
ul+h3,
ol+h3,
p+h4,
ul+h4,
ol+h4,
p+h5,
ul+h5,
ol+h5,
p+h6,
ul+h6,
ol+h6 {
  margin-top: 1.6em
}

.heading-collapsed+* {
  margin-top: 0
}

p+*,
ul+*,
ol+* {
  margin-top: .8em
}

ul,
ol {
  margin-left: 1.17647em
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-top: 0;
  margin-bottom: 0
}

nav ul,
nav ol {
  margin: 0;
  list-style: none
}

li li {
  font-size: 1em
}

a,
.link {
  color: #06c;
  letter-spacing: inherit;
}

a:link,
a:visited,
.link:link,
.link:visited {
  text-decoration: none
}

a:hover,
.link:hover {
  text-decoration: underline
}

a:active,
.link:active {
  text-decoration: none
}

a:disabled,
.link:disabled {
  opacity: .32
}

.links-inline,
.links-stacked {
  margin-left: 0;
  margin-right: 0;
  list-style: none
}

.links-inline li {
  display: inline;
  margin: 0 0.8em
}

.links-inline li:first-child {
  margin-left: 0
}

.links-inline li:last-child {
  margin-right: 0
}

.links-stacked li {
  margin: 0;
  display: block
}

.links-stacked li+li {
  margin-top: .4em
}

b,
strong {
  font-weight: 600
}

em,
i,
cite,
dfn {
  font-style: italic
}

.typography-headline-reduced+p,
.typography-headline-reduced+ul,
.typography-headline-reduced+.typography-body {
    margin-top: 1em
}

.typography-site-body,
.typography-site-body-reduced,
.typography-site-body-alt,
.typography-site-tile {
    color: #86868b
}

.typography-headline-super {
    font-size: 80px;
    line-height: 1.05;
    font-weight: 600;
    letter-spacing: -.015em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}

@media only screen and (max-width: 1068px) {
    .typography-headline-super {
        font-size: 64px;
        line-height: 1.0625;
        font-weight: 600;
        letter-spacing: -.009em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

@media only screen and (max-width: 734px) {
    .typography-headline-super {
        font-size: 48px;
        line-height: 1.08349;
        font-weight: 600;
        letter-spacing: -.003em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-headline-elevated {
    font-size: 64px;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.009em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 1068px) {
    .typography-headline-elevated {
        font-size: 48px;
        line-height: 1.08349;
        font-weight: 600;
        letter-spacing: -.003em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

@media only screen and (max-width: 734px) {
    .typography-headline-elevated {
        font-size: 40px;
        line-height: 1.1;
        font-weight: 600;
        letter-spacing: 0em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-headline {
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 600;
    letter-spacing: -.003em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 1068px) {
    .typography-headline {
        font-size: 40px;
        line-height: 1.1;
        font-weight: 600;
        letter-spacing: 0em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }


}

@media only screen and (max-width: 734px) {
    .typography-headline {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-headline-reduced {
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    letter-spacing: 0em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 1068px) {
    .typography-headline-reduced {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

@media only screen and (max-width: 734px) {
    .typography-headline-reduced {
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-eyebrow-reduced {
    font-size: 21px;
    line-height: 1.19048;
    font-weight: 600;
    letter-spacing: .011em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 734px) {
    .typography-eyebrow-reduced {
        font-size: 19px;
        line-height: 1.21053;
        font-weight: 600;
        letter-spacing: .012em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }
}

.typography-intro {
    font-size: 21px;
    line-height: 1.381;
    font-weight: 400;
    letter-spacing: .011em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 734px) {
    .typography-intro {
        font-size: 19px;
        line-height: 1.4211;
        font-weight: 400;
        letter-spacing: .012em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-callout {
    font-size: 32px;
    line-height: 1.125;
    font-weight: 600;
    letter-spacing: .004em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 1068px) {
    .typography-callout {
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

@media only screen and (max-width: 734px) {
    .typography-callout {
        font-size: 24px;
        line-height: 1.16667;
        font-weight: 600;
        letter-spacing: .009em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-manifesto {
    font-size: 32px;
    line-height: 1.25;
    font-weight: 600;
    letter-spacing: .004em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 1068px) {
    .typography-manifesto {
        font-size: 28px;
        line-height: 1.28583;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }


}

@media only screen and (max-width: 734px) {
    .typography-manifesto {
        font-size: 24px;
        line-height: 1.33341;
        font-weight: 600;
        letter-spacing: .009em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-label {
    font-size: 24px;
    line-height: 1.16667;
    font-weight: 600;
    letter-spacing: .009em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 1068px) {
    .typography-label {
        font-size: 21px;
        line-height: 1.19048;
        font-weight: 600;
        letter-spacing: .011em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-caption {
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -.01em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif
}


.typography-sosumi {
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -.01em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif
}


.typography-tiles-headline {
    font-size: 64px;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.009em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 1068px) {
    .typography-tiles-headline {
        font-size: 48px;
        line-height: 1.08349;
        font-weight: 600;
        letter-spacing: -.003em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

@media only screen and (max-width: 734px) {
    .typography-tiles-headline {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-facts-long-headline {
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 600;
    letter-spacing: -.003em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}

@media only screen and (max-width: 1068px) {
    .typography-facts-long-headline {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

@media only screen and (max-width: 734px) {
    .typography-facts-long-headline {
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

[class*="typography-eyebrow"] {
    display: block;
    margin-bottom: .4em
}

[class*="typography-eyebrow"]+* {
    margin-top: 0
}

.typography-headline+.typography-intro {
    margin-top: 1.2em
}

.typography-headline-super+.typography-intro-elevated {
    margin-top: 1.6em
}

.typography-headline-elevated+.typography-intro-elevated {
    margin-top: 1.4em
}

.typography-headline-reduced+p,
.typography-headline-reduced+ul,
.typography-headline-reduced+.typography-body {
    margin-top: 1em
}


.typography-site-headline {
    font-size: 64px;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.009em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}


@media only screen and (max-width: 1068px) {
    .typography-site-headline {
        font-size: 40px;
        line-height: 1.1;
        font-weight: 600;
        letter-spacing: 0em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

@media only screen and (max-width: 734px) {
    .typography-site-headline {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-site-subhead {
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 600;
    letter-spacing: -.003em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}

@media only screen and (max-width: 1068px) {
    .typography-site-subhead {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }
}

@media only screen and (max-width: 734px) {
    .typography-site-subhead {
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-site-headline-reduced {
    font-size: 28px;
    line-height: 1.14286;
    font-weight: 600;
    letter-spacing: .007em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}



@media only screen and (max-width: 1068px) {
    .typography-site-headline-reduced {
        font-size: 24px;
        line-height: 1.16667;
        font-weight: 600;
        letter-spacing: .009em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }
}

@media only screen and (max-width: 734px) {
    .typography-site-headline-reduced {
        font-size: 21px;
        line-height: 1.19048;
        font-weight: 600;
        letter-spacing: .011em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }
}

.typography-site-body {
    font-size: 21px;
    line-height: 1.19048;
    font-weight: 600;
    letter-spacing: .011em;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
}

@media only screen and (max-width: 1068px) {
    .typography-site-body {
        font-size: 17px;
        line-height: 1.29412;
        font-weight: 600;
        letter-spacing: -.022em;
        font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif
    }

}

@media only screen and (max-width: 734px) {
    .typography-site-body {
        font-size: 19px;
        line-height: 1.15798;
        font-weight: 600;
        letter-spacing: .012em;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important
    }

}

.typography-site-body-reduced {
    font-size: 14px;
    line-height: 1.42859;
    font-weight: 600;
    letter-spacing: -.016em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif
}


.typography-site-body-alt {
    font-size: 17px;
    line-height: 1.29412;
    font-weight: 600;
    letter-spacing: -.022em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif
}

@media only screen and (max-width: 1068px) {
    .typography-site-body-alt {
        font-size: 14px;
        line-height: 1.42859;
        font-weight: 600;
        letter-spacing: -.016em;
        font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif
    }
 
}

.typography-site-tile {
    font-size: 17px;
    line-height: 1.23536;
    font-weight: 600;
    letter-spacing: -.022em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif
}


@media only screen and (max-width: 734px) {
    .typography-site-tile {
        font-size: 17px;
        line-height: 1.23536;
        font-weight: 600;
        letter-spacing: -.022em;
        font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif
    }
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-column{
  flex-direction: column;
}

.column {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0px
}

.justify-content-start {
  justify-content: flex-start
}

.justify-content-end {
  justify-content: flex-end
}

.justify-content-center {
  justify-content: center
}

.justify-content-spacebetween {
  justify-content: space-between
}

.justify-content-spacearound {
  justify-content: space-around
}

.justify-content-spaceevenly {
  justify-content: space-evenly
}

.align-items-start {
  align-items: flex-start
}

.align-items-center {
  align-items: center
}

.align-items-end {
  align-items: flex-end
}

.align-self-start {
  align-self: flex-start
}

.align-self-center {
  align-self: center
}

.align-self-end {
  align-self: flex-end
}

.large-justify-content-start {
  justify-content: flex-start
}

.large-justify-content-end {
  justify-content: flex-end
}

.large-justify-content-center {
  justify-content: center
}

.large-justify-content-spacebetween {
  justify-content: space-between
}

.large-justify-content-spacearound {
  justify-content: space-around
}

.large-justify-content-spaceevenly {
  justify-content: space-evenly
}

.large-align-items-start {
  align-items: flex-start
}

.large-align-items-center {
  align-items: center
}

.large-align-items-end {
  align-items: flex-end
}

.large-align-self-start {
  align-self: flex-start
}

.large-align-self-center {
  align-self: center
}

.large-align-self-end {
  align-self: flex-end
}

@media only screen and (min-width: 1441px) {
  .xlarge-justify-content-start {
      justify-content: flex-start
  }

  .xlarge-justify-content-end {
      justify-content: flex-end
  }

  .xlarge-justify-content-center {
      justify-content: center
  }

  .xlarge-justify-content-spacebetween {
      justify-content: space-between
  }

  .xlarge-justify-content-spacearound {
      justify-content: space-around
  }

  .xlarge-justify-content-spaceevenly {
      justify-content: space-evenly
  }

  .xlarge-align-items-start {
      align-items: flex-start
  }

  .xlarge-align-items-center {
      align-items: center
  }

  .xlarge-align-items-end {
      align-items: flex-end
  }

  .xlarge-align-self-start {
      align-self: flex-start
  }

  .xlarge-align-self-center {
      align-self: center
  }

  .xlarge-align-self-end {
      align-self: flex-end
  }
}

@media only screen and (max-width: 1068px) {
  .medium-justify-content-start {
      justify-content: flex-start
  }

  .medium-justify-content-end {
      justify-content: flex-end
  }

  .medium-justify-content-center {
      justify-content: center
  }

  .medium-justify-content-spacebetween {
      justify-content: space-between
  }

  .medium-justify-content-spacearound {
      justify-content: space-around
  }

  .medium-justify-content-spaceevenly {
      justify-content: space-evenly
  }

  .medium-align-items-start {
      align-items: flex-start
  }

  .medium-align-items-center {
      align-items: center
  }

  .medium-align-items-end {
      align-items: flex-end
  }

  .medium-align-self-start {
      align-self: flex-start
  }

  .medium-align-self-center {
      align-self: center
  }

  .medium-align-self-end {
      align-self: flex-end
  }
}

@media only screen and (max-width: 734px) {
  .small-justify-content-start {
      justify-content: flex-start
  }

  .small-justify-content-end {
      justify-content: flex-end
  }

  .small-justify-content-center {
      justify-content: center
  }

  .small-justify-content-spacebetween {
      justify-content: space-between
  }

  .small-justify-content-spacearound {
      justify-content: space-around
  }

  .small-justify-content-spaceevenly {
      justify-content: space-evenly
  }

  .small-align-items-start {
      align-items: flex-start
  }

  .small-align-items-center {
      align-items: center
  }

  .small-align-items-end {
      align-items: flex-end
  }

  .small-align-self-start {
      align-self: flex-start
  }

  .small-align-self-center {
      align-self: center
  }

  .small-align-self-end {
      align-self: flex-end
  }
}

@media only screen and (max-width: 350px) {
  .xsmall-justify-content-start {
      justify-content: flex-start
  }

  .xsmall-justify-content-end {
      justify-content: flex-end
  }

  .xsmall-justify-content-center {
      justify-content: center
  }

  .xsmall-justify-content-spacebetween {
      justify-content: space-between
  }

  .xsmall-justify-content-spacearound {
      justify-content: space-around
  }

  .xsmall-justify-content-spaceevenly {
      justify-content: space-evenly
  }

  .xsmall-align-items-start {
      align-items: flex-start
  }

  .xsmall-align-items-center {
      align-items: center
  }

  .xsmall-align-items-end {
      align-items: flex-end
  }

  .xsmall-align-self-start {
      align-self: flex-start
  }

  .xsmall-align-self-center {
      align-self: center
  }

  .xsmall-align-self-end {
      align-self: flex-end
  }
}

.large-offset-0 {
  margin-left: 0
}

.large-order-0 {
  order: 0
}

.large-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%
}

.large-offset-1 {
  margin-left: 8.33333%
}

.large-order-1 {
  order: 1
}

.large-2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%
}

.large-offset-2 {
  margin-left: 16.66667%
}

.large-order-2 {
  order: 2
}

.large-3 {
  flex-basis: 25%;
  max-width: 25%
}

.large-offset-3 {
  margin-left: 25%
}

.large-order-3 {
  order: 3
}

.large-4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%
}

.large-offset-4 {
  margin-left: 33.33333%
}

.large-order-4 {
  order: 4
}

.large-5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%
}

.large-offset-5 {
  margin-left: 41.66667%
}

.large-order-5 {
  order: 5
}

.large-6 {
  flex-basis: 50%;
  max-width: 50%
}

.large-offset-6 {
  margin-left: 50%
}

.large-order-6 {
  order: 6
}

.large-7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%
}

.large-offset-7 {
  margin-left: 58.33333%
}

.large-order-7 {
  order: 7
}

.large-8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%
}

.large-offset-8 {
  margin-left: 66.66667%
}

.large-order-8 {
  order: 8
}

.large-9 {
  flex-basis: 75%;
  max-width: 75%
}

.large-offset-9 {
  margin-left: 75%
}

.large-order-9 {
  order: 9
}

.large-10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%
}

.large-offset-10 {
  margin-left: 83.33333%
}

.large-order-10 {
  order: 10
}

.large-11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%
}

.large-offset-11 {
  margin-left: 91.66667%
}

.large-order-11 {
  order: 11
}

.large-12 {
  flex-basis: 100%;
  max-width: 100%
}

.large-offset-12 {
  margin-left: 100%
}

.large-order-12 {
  order: 12
}

.large-centered {
  margin-left: auto;
  margin-right: auto
}

.large-uncentered {
  margin-left: 0;
  margin-right: 0
}

.large-last {
  margin-left: auto
}

.large-notlast {
  margin-left: 0
}

.large-grow {
  flex: auto;
  max-width: initial
}

.large-ungrow {
  flex: initial;
  max-width: initial
}

@media only screen and (min-width: 1441px) {
  .xlarge-offset-0 {
      margin-left: 0
  }

  .xlarge-order-0 {
      order: 0
  }

  .xlarge-1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%
  }

  .xlarge-offset-1 {
      margin-left: 8.33333%
  }

  .xlarge-order-1 {
      order: 1
  }

  .xlarge-2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%
  }

  .xlarge-offset-2 {
      margin-left: 16.66667%
  }

  .xlarge-order-2 {
      order: 2
  }

  .xlarge-3 {
      flex-basis: 25%;
      max-width: 25%
  }

  .xlarge-offset-3 {
      margin-left: 25%
  }

  .xlarge-order-3 {
      order: 3
  }

  .xlarge-4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%
  }

  .xlarge-offset-4 {
      margin-left: 33.33333%
  }

  .xlarge-order-4 {
      order: 4
  }

  .xlarge-5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%
  }

  .xlarge-offset-5 {
      margin-left: 41.66667%
  }

  .xlarge-order-5 {
      order: 5
  }

  .xlarge-6 {
      flex-basis: 50%;
      max-width: 50%
  }

  .xlarge-offset-6 {
      margin-left: 50%
  }

  .xlarge-order-6 {
      order: 6
  }

  .xlarge-7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%
  }

  .xlarge-offset-7 {
      margin-left: 58.33333%
  }

  .xlarge-order-7 {
      order: 7
  }

  .xlarge-8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%
  }

  .xlarge-offset-8 {
      margin-left: 66.66667%
  }

  .xlarge-order-8 {
      order: 8
  }

  .xlarge-9 {
      flex-basis: 75%;
      max-width: 75%
  }

  .xlarge-offset-9 {
      margin-left: 75%
  }

  .xlarge-order-9 {
      order: 9
  }

  .xlarge-10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%
  }

  .xlarge-offset-10 {
      margin-left: 83.33333%
  }

  .xlarge-order-10 {
      order: 10
  }

  .xlarge-11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%
  }

  .xlarge-offset-11 {
      margin-left: 91.66667%
  }

  .xlarge-order-11 {
      order: 11
  }

  .xlarge-12 {
      flex-basis: 100%;
      max-width: 100%
  }

  .xlarge-offset-12 {
      margin-left: 100%
  }

  .xlarge-order-12 {
      order: 12
  }

  .xlarge-centered {
      margin-left: auto;
      margin-right: auto
  }

  .xlarge-uncentered {
      margin-left: 0;
      margin-right: 0
  }

  .xlarge-last {
      margin-left: auto
  }

  .xlarge-notlast {
      margin-left: 0
  }

  .xlarge-grow {
      flex: auto;
      max-width: initial
  }

  .xlarge-ungrow {
      flex: initial;
      max-width: initial
  }
}

@media only screen and (max-width: 1068px) {
  .medium-offset-0 {
      margin-left: 0
  }

  .medium-order-0 {
      order: 0
  }

  .medium-1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%
  }

  .medium-offset-1 {
      margin-left: 8.33333%
  }

  .medium-order-1 {
      order: 1
  }

  .medium-2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%
  }

  .medium-offset-2 {
      margin-left: 16.66667%
  }

  .medium-order-2 {
      order: 2
  }

  .medium-3 {
      flex-basis: 25%;
      max-width: 25%
  }

  .medium-offset-3 {
      margin-left: 25%
  }

  .medium-order-3 {
      order: 3
  }

  .medium-4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%
  }

  .medium-offset-4 {
      margin-left: 33.33333%
  }

  .medium-order-4 {
      order: 4
  }

  .medium-5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%
  }

  .medium-offset-5 {
      margin-left: 41.66667%
  }

  .medium-order-5 {
      order: 5
  }

  .medium-6 {
      flex-basis: 50%;
      max-width: 50%
  }

  .medium-offset-6 {
      margin-left: 50%
  }

  .medium-order-6 {
      order: 6
  }

  .medium-7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%
  }

  .medium-offset-7 {
      margin-left: 58.33333%
  }

  .medium-order-7 {
      order: 7
  }

  .medium-8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%
  }

  .medium-offset-8 {
      margin-left: 66.66667%
  }

  .medium-order-8 {
      order: 8
  }

  .medium-9 {
      flex-basis: 75%;
      max-width: 75%
  }

  .medium-offset-9 {
      margin-left: 75%
  }

  .medium-order-9 {
      order: 9
  }

  .medium-10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%
  }

  .medium-offset-10 {
      margin-left: 83.33333%
  }

  .medium-order-10 {
      order: 10
  }

  .medium-11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%
  }

  .medium-offset-11 {
      margin-left: 91.66667%
  }

  .medium-order-11 {
      order: 11
  }

  .medium-12 {
      flex-basis: 100%;
      max-width: 100%
  }

  .medium-offset-12 {
      margin-left: 100%
  }

  .medium-order-12 {
      order: 12
  }

  .medium-centered {
      margin-left: auto;
      margin-right: auto
  }

  .medium-uncentered {
      margin-left: 0;
      margin-right: 0
  }

  .medium-last {
      margin-left: auto
  }

  .medium-notlast {
      margin-left: 0
  }

  .medium-grow {
      flex: auto;
      max-width: initial
  }

  .medium-ungrow {
      flex: initial;
      max-width: initial
  }
}

@media only screen and (max-width: 734px) {
  .small-offset-0 {
      margin-left: 0
  }

  .small-order-0 {
      order: 0
  }

  .small-1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%
  }

  .small-offset-1 {
      margin-left: 8.33333%
  }

  .small-order-1 {
      order: 1
  }

  .small-2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%
  }

  .small-offset-2 {
      margin-left: 16.66667%
  }

  .small-order-2 {
      order: 2
  }

  .small-3 {
      flex-basis: 25%;
      max-width: 25%
  }

  .small-offset-3 {
      margin-left: 25%
  }

  .small-order-3 {
      order: 3
  }

  .small-4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%
  }

  .small-offset-4 {
      margin-left: 33.33333%
  }

  .small-order-4 {
      order: 4
  }

  .small-5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%
  }

  .small-offset-5 {
      margin-left: 41.66667%
  }

  .small-order-5 {
      order: 5
  }

  .small-6 {
      flex-basis: 50%;
      max-width: 50%
  }

  .small-offset-6 {
      margin-left: 50%
  }

  .small-order-6 {
      order: 6
  }

  .small-7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%
  }

  .small-offset-7 {
      margin-left: 58.33333%
  }

  .small-order-7 {
      order: 7
  }

  .small-8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%
  }

  .small-offset-8 {
      margin-left: 66.66667%
  }

  .small-order-8 {
      order: 8
  }

  .small-9 {
      flex-basis: 75%;
      max-width: 75%
  }

  .small-offset-9 {
      margin-left: 75%
  }

  .small-order-9 {
      order: 9
  }

  .small-10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%
  }

  .small-offset-10 {
      margin-left: 83.33333%
  }

  .small-order-10 {
      order: 10
  }

  .small-11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%
  }

  .small-offset-11 {
      margin-left: 91.66667%
  }

  .small-order-11 {
      order: 11
  }

  .small-12 {
      flex-basis: 100%;
      max-width: 100%
  }

  .small-offset-12 {
      margin-left: 100%
  }

  .small-order-12 {
      order: 12
  }

  .small-centered {
      margin-left: auto;
      margin-right: auto
  }

  .small-uncentered {
      margin-left: 0;
      margin-right: 0
  }

  .small-last {
      margin-left: auto
  }

  .small-notlast {
      margin-left: 0
  }

  .small-grow {
      flex: auto;
      max-width: initial
  }

  .small-ungrow {
      flex: initial;
      max-width: initial
  }
}

@media only screen and (max-width: 350px) {
  .xsmall-offset-0 {
      margin-left: 0
  }

  .xsmall-order-0 {
      order: 0
  }

  .xsmall-1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%
  }

  .xsmall-offset-1 {
      margin-left: 8.33333%
  }

  .xsmall-order-1 {
      order: 1
  }

  .xsmall-2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%
  }

  .xsmall-offset-2 {
      margin-left: 16.66667%
  }

  .xsmall-order-2 {
      order: 2
  }

  .xsmall-3 {
      flex-basis: 25%;
      max-width: 25%
  }

  .xsmall-offset-3 {
      margin-left: 25%
  }

  .xsmall-order-3 {
      order: 3
  }

  .xsmall-4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%
  }

  .xsmall-offset-4 {
      margin-left: 33.33333%
  }

  .xsmall-order-4 {
      order: 4
  }

  .xsmall-5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%
  }

  .xsmall-offset-5 {
      margin-left: 41.66667%
  }

  .xsmall-order-5 {
      order: 5
  }

  .xsmall-6 {
      flex-basis: 50%;
      max-width: 50%
  }

  .xsmall-offset-6 {
      margin-left: 50%
  }

  .xsmall-order-6 {
      order: 6
  }

  .xsmall-7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%
  }

  .xsmall-offset-7 {
      margin-left: 58.33333%
  }

  .xsmall-order-7 {
      order: 7
  }

  .xsmall-8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%
  }

  .xsmall-offset-8 {
      margin-left: 66.66667%
  }

  .xsmall-order-8 {
      order: 8
  }

  .xsmall-9 {
      flex-basis: 75%;
      max-width: 75%
  }

  .xsmall-offset-9 {
      margin-left: 75%
  }

  .xsmall-order-9 {
      order: 9
  }

  .xsmall-10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%
  }

  .xsmall-offset-10 {
      margin-left: 83.33333%
  }

  .xsmall-order-10 {
      order: 10
  }

  .xsmall-11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%
  }

  .xsmall-offset-11 {
      margin-left: 91.66667%
  }

  .xsmall-order-11 {
      order: 11
  }

  .xsmall-12 {
      flex-basis: 100%;
      max-width: 100%
  }

  .xsmall-offset-12 {
      margin-left: 100%
  }

  .xsmall-order-12 {
      order: 12
  }

  .xsmall-centered {
      margin-left: auto;
      margin-right: auto
  }

  .xsmall-uncentered {
      margin-left: 0;
      margin-right: 0
  }

  .xsmall-last {
      margin-left: auto
  }

  .xsmall-notlast {
      margin-left: 0
  }

  .xsmall-grow {
      flex: auto;
      max-width: initial
  }

  .xsmall-ungrow {
      flex: initial;
      max-width: initial
  }
}

.row-reverse .column {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0px
}

.row-reverse .large-offset-0 {
  margin-right: 0
}

.row-reverse .large-offset-1 {
  margin-right: 8.33333%
}

.row-reverse .large-offset-2 {
  margin-right: 16.66667%
}

.row-reverse .large-offset-3 {
  margin-right: 25%
}

.row-reverse .large-offset-4 {
  margin-right: 33.33333%
}

.row-reverse .large-offset-5 {
  margin-right: 41.66667%
}

.row-reverse .large-offset-6 {
  margin-right: 50%
}

.row-reverse .large-offset-7 {
  margin-right: 58.33333%
}

.row-reverse .large-offset-8 {
  margin-right: 66.66667%
}

.row-reverse .large-offset-9 {
  margin-right: 75%
}

.row-reverse .large-offset-10 {
  margin-right: 83.33333%
}

.row-reverse .large-offset-11 {
  margin-right: 91.66667%
}

.row-reverse .large-offset-12 {
  margin-right: 100%
}

.row-reverse .large-last {
  margin-right: auto
}

.row-reverse .large-notlast {
  margin-right: 0
}

@media only screen and (min-width: 1441px) {
  .row-reverse .xlarge-offset-0 {
      margin-right: 0
  }

  .row-reverse .xlarge-offset-1 {
      margin-right: 8.33333%
  }

  .row-reverse .xlarge-offset-2 {
      margin-right: 16.66667%
  }

  .row-reverse .xlarge-offset-3 {
      margin-right: 25%
  }

  .row-reverse .xlarge-offset-4 {
      margin-right: 33.33333%
  }

  .row-reverse .xlarge-offset-5 {
      margin-right: 41.66667%
  }

  .row-reverse .xlarge-offset-6 {
      margin-right: 50%
  }

  .row-reverse .xlarge-offset-7 {
      margin-right: 58.33333%
  }

  .row-reverse .xlarge-offset-8 {
      margin-right: 66.66667%
  }

  .row-reverse .xlarge-offset-9 {
      margin-right: 75%
  }

  .row-reverse .xlarge-offset-10 {
      margin-right: 83.33333%
  }

  .row-reverse .xlarge-offset-11 {
      margin-right: 91.66667%
  }

  .row-reverse .xlarge-offset-12 {
      margin-right: 100%
  }

  .row-reverse .xlarge-last {
      margin-right: auto
  }

  .row-reverse .xlarge-notlast {
      margin-right: 0
  }
}

@media only screen and (max-width: 1068px) {
  .row-reverse .medium-offset-0 {
      margin-right: 0
  }

  .row-reverse .medium-offset-1 {
      margin-right: 8.33333%
  }

  .row-reverse .medium-offset-2 {
      margin-right: 16.66667%
  }

  .row-reverse .medium-offset-3 {
      margin-right: 25%
  }

  .row-reverse .medium-offset-4 {
      margin-right: 33.33333%
  }

  .row-reverse .medium-offset-5 {
      margin-right: 41.66667%
  }

  .row-reverse .medium-offset-6 {
      margin-right: 50%
  }

  .row-reverse .medium-offset-7 {
      margin-right: 58.33333%
  }

  .row-reverse .medium-offset-8 {
      margin-right: 66.66667%
  }

  .row-reverse .medium-offset-9 {
      margin-right: 75%
  }

  .row-reverse .medium-offset-10 {
      margin-right: 83.33333%
  }

  .row-reverse .medium-offset-11 {
      margin-right: 91.66667%
  }

  .row-reverse .medium-offset-12 {
      margin-right: 100%
  }

  .row-reverse .medium-last {
      margin-right: auto
  }

  .row-reverse .medium-notlast {
      margin-right: 0
  }
}

@media only screen and (max-width: 734px) {
  .row-reverse .small-offset-0 {
      margin-right: 0
  }

  .row-reverse .small-offset-1 {
      margin-right: 8.33333%
  }

  .row-reverse .small-offset-2 {
      margin-right: 16.66667%
  }

  .row-reverse .small-offset-3 {
      margin-right: 25%
  }

  .row-reverse .small-offset-4 {
      margin-right: 33.33333%
  }

  .row-reverse .small-offset-5 {
      margin-right: 41.66667%
  }

  .row-reverse .small-offset-6 {
      margin-right: 50%
  }

  .row-reverse .small-offset-7 {
      margin-right: 58.33333%
  }

  .row-reverse .small-offset-8 {
      margin-right: 66.66667%
  }

  .row-reverse .small-offset-9 {
      margin-right: 75%
  }

  .row-reverse .small-offset-10 {
      margin-right: 83.33333%
  }

  .row-reverse .small-offset-11 {
      margin-right: 91.66667%
  }

  .row-reverse .small-offset-12 {
      margin-right: 100%
  }

  .row-reverse .small-last {
      margin-right: auto
  }

  .row-reverse .small-notlast {
      margin-right: 0
  }
}

@media only screen and (max-width: 350px) {
  .row-reverse .xsmall-offset-0 {
      margin-right: 0
  }

  .row-reverse .xsmall-offset-1 {
      margin-right: 8.33333%
  }

  .row-reverse .xsmall-offset-2 {
      margin-right: 16.66667%
  }

  .row-reverse .xsmall-offset-3 {
      margin-right: 25%
  }

  .row-reverse .xsmall-offset-4 {
      margin-right: 33.33333%
  }

  .row-reverse .xsmall-offset-5 {
      margin-right: 41.66667%
  }

  .row-reverse .xsmall-offset-6 {
      margin-right: 50%
  }

  .row-reverse .xsmall-offset-7 {
      margin-right: 58.33333%
  }

  .row-reverse .xsmall-offset-8 {
      margin-right: 66.66667%
  }

  .row-reverse .xsmall-offset-9 {
      margin-right: 75%
  }

  .row-reverse .xsmall-offset-10 {
      margin-right: 83.33333%
  }

  .row-reverse .xsmall-offset-11 {
      margin-right: 91.66667%
  }

  .row-reverse .xsmall-offset-12 {
      margin-right: 100%
  }

  .row-reverse .xsmall-last {
      margin-right: auto
  }

  .row-reverse .xsmall-notlast {
      margin-right: 0
  }
}


/* animations */
@-moz-keyframes fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: .6
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: .6
    }
}

@-o-keyframes fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: .6
    }
}


@keyframes fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: .6
    }
}

@-moz-keyframes fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-o-keyframes fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-ms-keyframes fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-khtml-keyframes fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}


.fade-in {
    -webkit-animation: fade-in .4s ease-in-out;
    -moz-animation: fade-in .4s ease-in-out;
    -ms-animation: fade-in .4s ease-in-out;
    -o-animation: fade-in .4s ease-in-out;
    animation: fade-in .4s ease-in-out
}

.fade-out {
    -webkit-animation: fade-out .4s ease-in-out;
    -moz-animation: fade-out .4s ease-in-out;
    -ms-animation: fade-out .4s ease-in-out;
    -o-animation: fade-out .4s ease-in-out;
    animation: fade-out .4s ease-in-out
}

.position-absolute{
    position: absolute;
}

.position-relative{
    position: relative;
}

.top-0{
    top: 0;
}

.bottom-0{
    bottom: 0;
}

.right-0{
    right: 0
}

.left-0{
    left: 0;
}

.width-full{
    width: 100%;
}

.height-full{
    height: 100%;
}

.bg-size-cover{
    background-size: cover !important;
    background-position: 50% 50%;
}

.bg-norepeat{
    background-repeat: no-repeat;
}

.object-fit-cover {
    object-fit: cover;
    object-position: bottom;
}

.hide{
    display: none;
}

.no-visible{
    /*visibily hidden is different to "display none"*/
    visibility: hidden;
}

.centered{
    margin: auto;
}

.text-center{
    text-align: center;
}

.App{
  --dynamic-height: 100vh;
  --dynamic-screen-image-width: 100%;
  width: 100%;
  height: /*var(--dynamic-height);*/ 100vh;
  /*max-height: var(--dynamic-height);*/;
  overflow: hidden;

  background-color: #b2d7e091;
}

@font-face {
    font-family: 'Fredoka One';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/fredokaone/v13/k3kUo8kEI-tA1RRcTZGmTlHGCaen8wf-.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.font-fredoka-one{
    font-family: 'Fredoka One', -apple-system,BlinkMacSystemFont,"Segoe UI", 'proxima-nova',Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
}

.button-shadow{
    -webkit-box-shadow: 4px 12px 40px 6px rgb(0 0 0 / 9%);
    box-shadow: 4px 12px 40px 6px rgb(0 0 0 / 9%);
}

.button-language, .button-city{
   background-color:rgba(255, 255, 255, 0.8)!important;
}

.button-language.active, .button-city.active{
    background-color: rgba(255, 159, 137, 0.8)!important;
    color: #fff;
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled{
    opacity: 0!important;
}

.bg-sature{
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
}

.bg-white{
    background-color: #fff;
}

.width-image-screen-reference{
  width: /*var(--dynamic-screen-image-width)*/ 100%;
  min-width: 300px;
}

.screen{
  background-color: rgb(255, 255, 255);
  height: /*var(--dynamic-height);*/ 100vh;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  aspect-ratio: auto 390 / 844;
  max-width: 450px;
}

.screen-image-background-blur-sature{
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.72);
}

.screen-image{
  height: 100%;
  z-index: 2;
}

@media only screen and (max-width: 735px){
  .screen-image{
    height: 100%;
  }
}

.screen-container{
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  width: 100%; /*var(--dynamic-screen-image-width);*/
}

.screen-container .screen-content{
  width: 100%;
  height: 100%;
  position: relative;
  
}

.screen-character-pose-container{
    position: absolute;
    bottom: 1.4%;
    left: -3.6%;
    width: 70%;
}

@media only screen and (max-width: 7035px){
    .screen-character-pose-container{
        /*bottom: 10.35%;
        left: -3.7%;
        width: 71%;*/
        bottom: 7.35%;
        left: -10.7%;
        width: 71%;
        height: 40%;
    }

    .screen-character-pose-container.party{
        top: 35.2%;
        width: 41%;
        height: 30%;
        left: inherit!important;
        transform: translateX(-16.6%);
    }

    .screen-character-pose-container.right{
        transform: translate(59%, 2%);
    }
}

.app-nav{
  height: /*60px*/80px;
  position: absolute;
  width: 100%;
  z-index: 5;
}

.app-nav-bottom{
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(38, 43, 39, 0.761);
  bottom: 0;
  z-index: 999;
}

svg.character-dog-geek{
  --geek-dress-color: #efefef;
  --geek-glasses-color: #1d1d1f;
  --geek-tie-color: #c62319;
}


svg.character-dog-fashion{
  --fashion-dress-color: #ffecaf;
  --fashion-glasses-color: #1d1d1f;
  --fashion-tie-color: #c62319;
}

svg.character-dog-sport{
  --sport-dress-color: #222c4c;
}

svg.character-dog-music{
  --music-dress-color: #661212;
}


.swiper-button-next, .swiper-button-prev {
    width: 42px!important;
    height: 42px!important;
    border-radius: 50%;
    display: flex;
    background: rgba(0, 0, 0, 0.212);
    /*-webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);*/
}

.swiper-button-next:after,.swiper-button-prev:after {
    transform: scale(0.5);
    color: rgba(255, 255, 255, 0.884);
}

body {
    overflow: hidden;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.26);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

.screen-container {
    bottom: calc(11vh);
}
