
/* 
    *POSE SPORT HAPPY 
*/
.screen-character-pose-container#pose-sport-happy {}

.screen-character-pose-container#pose-sport-happy svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-sport-happy {
        bottom: 7.8%;
        left: -7%;
    }

    .screen-character-pose-container#pose-sport-happy svg {
        width: 100%;
    }
}

/*
    * POSE SPORT THINK 
*/
.screen-character-pose-container#pose-sport-think {}

.screen-character-pose-container#pose-sport-think svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-sport-think {
        bottom: 8%;
        left: -4%;
    }

    .screen-character-pose-container#pose-sport-think svg {
        width: 85%;
    }
}

/* 
    *POSE SPORT SPEAK 
*/
.screen-character-pose-container#pose-sport-speak {}

.screen-character-pose-container#pose-sport-speak svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-sport-speak {
        bottom: 5%;
        left: -1.8%;
    }

    .screen-character-pose-container#pose-sport-speak svg {
        width: 85%;
    }
}

/* 
    *POSE SPORT SPEAK TILT
*/
.screen-character-pose-container#pose-sport-speak {}

.screen-character-pose-container#pose-sport-speak-tilt svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-sport-speak-tilt {
        bottom: -3.2%;
        left: -19.9%;
    }

    .screen-character-pose-container#pose-sport-speak-tilt svg {
        width: 105%;
    }
}


/* 
    *POSE MUSIC HAPPY 
*/
.screen-character-pose-container#pose-music-happy {}

.screen-character-pose-container#pose-music-happy svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-music-happy {
        bottom: 7.8%;
        left: -11.5%;
    }

    .screen-character-pose-container#pose-music-happy svg {
        width: 110%;
    }
}

/* 
    *POSE MUSIC THINK 
*/
.screen-character-pose-container#pose-music-think {}

.screen-character-pose-container#pose-music-think svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-music-think {
        bottom: 7.8%;
        left: -9.8%;
    }

    .screen-character-pose-container#pose-music-think svg {
        width: 105%;
    }
}


/* 
    *POSE MUSIC SPEAK 
*/
.screen-character-pose-container#pose-music-speak {}

.screen-character-pose-container#pose-music-speak svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-music-speak {
        bottom: 7.35%;
        left: -4.45%;
    }

    .screen-character-pose-container#pose-music-speak svg {
        width: 105%;
    }
}

/* 
    *POSE MUSIC SPEAK TILT
*/
.screen-character-pose-container#pose-music-speak {}

.screen-character-pose-container#pose-music-speak-tilt svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-music-speak-tilt {
        bottom: -2.2%;
        left: -26.9%;
    }

    .screen-character-pose-container#pose-music-speak-tilt svg {
        width: 115%;
    }
}


/* 
    *POSE FASHION HAPPY 
*/
.screen-character-pose-container#pose-fashion-happy {}

.screen-character-pose-container#pose-fashion-happy svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-fashion-happy {
        bottom: 7.2%;
        left: -3.6%;
    }

    .screen-character-pose-container#pose-fashion-happy svg {
        width: 100%;
    }
}

/* 
    *POSE FASHION THINK 
*/
.screen-character-pose-container#pose-fashion-think {}

.screen-character-pose-container#pose-fashion-think svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-fashion-think {
        bottom: 7.2%;
        left: -3.9%;
    }

    .screen-character-pose-container#pose-fashion-think svg {
        width: 94%;
    }
}

/* 
    *POSE FASHION SPEAK 
*/
.screen-character-pose-container#pose-fashion-speak {}

.screen-character-pose-container#pose-fashion-speak svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-fashion-speak {
        bottom: 5.8%;
        left: -6.25%;
    }

    .screen-character-pose-container#pose-fashion-speak svg {
        width: 85%;
    }
}

/* 
    *POSE FASHION SPEAK TILT
*/
.screen-character-pose-container#pose-fashion-speak {}

.screen-character-pose-container#pose-fashion-speak-tilt svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-fashion-speak-tilt {
        bottom: -3.2%;
        left: -24%;
    }

    .screen-character-pose-container#pose-fashion-speak-tilt svg {
        width: 115%;
    }
}

/* 
    *POSE GEEK HAPPY 
*/
.screen-character-pose-container#pose-geek-happy {}

.screen-character-pose-container#pose-geek-happy svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-geek-happy {
        bottom: 4%;
        left: -9.8%;
    }

    .screen-character-pose-container.party#pose-geek-happy{
        top: 37%;
        transform: translateX(-26%);
    }

    .screen-character-pose-container#pose-geek-happy svg {
        width: 115%;
    }
}

/* 
    *POSE GEEK THINK 
*/
.screen-character-pose-container#pose-geek-think {}

.screen-character-pose-container#pose-geek-think svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-geek-think {
        bottom: 7.4%;
        left: -3.9%;
    }

    .screen-character-pose-container#pose-geek-think svg {
        width: 100%;
    }
}

/* 
    *POSE GEEK SPEAK 
*/
.screen-character-pose-container#pose-geek-speak {}

.screen-character-pose-container#pose-geek-speak svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-geek-speak {
        bottom: 4.7%;
        left: -5%;
    }

    .screen-character-pose-container#pose-geek-speak svg {
        width: 91%;
    }
}

/* 
    *POSE GEEK SPEAK TILT 
*/
.screen-character-pose-container#pose-geek-speak {}

.screen-character-pose-container#pose-geek-speak-tilt svg {
    width: 110%;
}

@media only screen and (max-width: 7035px) {
    .screen-character-pose-container#pose-geek-speak-tilt {
        bottom: -4.5%;
        left: -20%;
    }

    .screen-character-pose-container#pose-geek-speak-tilt svg {
        width: 100%;
    }
}

/* 
    *POSE  NEUTRAL  FOR ALL
*/
.screen-character-pose-container#pose-sport-neutral,
.screen-character-pose-container#pose-music-neutral,
.screen-character-pose-container#pose-geek-neutral,
.screen-character-pose-container#pose-fashion-neutral {}

.screen-character-pose-container#pose-sport-neutral svg,
.screen-character-pose-container#pose-music-neutral svg,
.screen-character-pose-container#pose-geek-neutral svg,
.screen-character-pose-container#pose-fashion-neutral svg {
    width: 100%;
    transform: translateY(-32%);
}

@media only screen and (max-width: 7035px) {

    .screen-character-pose-container#pose-sport-neutral,
    .screen-character-pose-container#pose-music-neutral,
    .screen-character-pose-container#pose-geek-neutral,
    .screen-character-pose-container#pose-fashion-neutral {
        left: -21.7%;
    }

    .screen-character-pose-container.party#pose-sport-neutral,
    .screen-character-pose-container.party#pose-music-neutral,
    .screen-character-pose-container.party#pose-geek-neutral,
    .screen-character-pose-container.party#pose-fashion-neutral {
        top: 34.7%;
        transform: translateX(-40%);
    }

    .screen-character-pose-container#pose-sport-neutral svg,
    .screen-character-pose-container#pose-music-neutral svg,
    .screen-character-pose-container#pose-geek-neutral svg,
    .screen-character-pose-container#pose-fashion-neutral svg {
        width: 145%;
    }
}