.FloatingButtonContainer_listeningScreen__wvAGc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow-y: hidden
}

.ListeningScreen_listeningContainer__Xc0Ji {
  background-image: linear-gradient(180deg,#0bf,#066aff);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  touch-action: none
}

.ListeningScreen_main___A3XZ {
  height: 100svh
}

.ListeningScreen_main___A3XZ,.ListeningScreen_textContainer__YJhEO {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.ListeningScreen_textContainer__YJhEO {
  text-align: center;
  position: absolute;
  left: 0;
  margin-top: min(30px,2rem);
  width: 100%
}

.ListeningScreen_shazamLogo__at8Vs {
  --shazam-logo-circle-fill: hsla(0,0%,100%,.3);
  --shazam-logo-circle-opacity: 1;
  --shazam-logo-s-fill: #fff;
  fill: #fff
}

.ListeningScreen_logoHeadingContainer__2iWqT {
  height: 0;
  position: fixed;
  top: 30px;
  left: 30px
}

.ListeningScreen_buttonContainer__wDbXr,.ListeningScreen_button__GgzT9 {
  position: absolute;
  left: 50%;
  top: 40%;
  background-color: #fff;
  border-radius: 100%;
  z-index: 200;
  transform: translate(-50%,-40%)
}

.ListeningScreen_listeningCircle__BxSr1 {
  background-color: hsla(0,0%,100%,.4);
  opacity: 0;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 40%;
  height: 150px;
  width: 150px;
  z-index: 99;
  transform: translate(-50%,-40%);
  animation: ListeningScreen_growAndFade__Hv1Ge 3s cubic-bezier(.36,.11,.89,.32) infinite
}

.ListeningScreen_cancelContainer__uDo3e {
  position: fixed;
  bottom: 10%;
  width: 285px
}

#ListeningScreen_c1__XPuPe {
  animation-delay: 0s
}

#ListeningScreen_c2__H_qIq {
  animation-delay: .4s
}

#ListeningScreen_c3__PsaiD {
  animation-delay: .8s
}

#ListeningScreen_c4__QgcAb {
  animation-delay: 1.2s
}

@keyframes ListeningScreen_growAndFade__Hv1Ge {
  0% {
    height: 150px;
    width: 150px
  }

  50% {
    opacity: .2
  }

  to {
    height: 600px;
    width: 600px;
    opacity: 0
  }
}

@media (min-width: 768px) {
  .ListeningScreen_listeningContainer__Xc0Ji {
    padding:0 35px
  }

  .ListeningScreen_textContainer__YJhEO {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    left: 0;
    margin-top: min(1rem,40px);
    width: 100%
  }
}